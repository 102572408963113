import { type AuthProfileMeRouteRpc, RoleType } from '@repo/contract';
import { computed } from 'vue';
import { sessionTokenKey } from '../../../types/session';
import { useCookie, useFetch } from '#app';

export interface Profile {
  isAdmin: boolean
  isVerified: boolean
  fullName: string
  email: string
}

export function useProfile() {
  const token = useCookie(sessionTokenKey);

  const { data, error, execute, status } = useFetch<typeof AuthProfileMeRouteRpc.response>('/api/rpc/auth/profile/me', {
    method: 'POST',
    immediate: true,
    headers: {
      token: token.value,
    },
  });

  const profile = computed<Profile>(() => ({
    isAdmin: data.value?.profile?.role >= RoleType.admin || false,
    isVerified: data.value?.profile?.role >= RoleType.user || false,
    fullName: data.value?.profile?.firstName ? `${data.value.profile?.firstName} ${data.value.profile?.lastName}` : '',
    email: data.value?.profile?.email || '',
  }));

  return { profile, error, execute, status };
}
