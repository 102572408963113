<script lang="ts" setup>
import LogoBig from '../logo/logo-big.vue';

const menuElements = [
  {
    name: 'Policies',
    to: '/policies',
  },
  {
    name: 'Shipping',
    to: '/shipping',
  },
  {
    name: 'Learn',
    to: '/learn',
  },
  {
    name: 'Blog',
    to: '/blog',
  },
];

const socials = [
  {
    icon: 'ant-design:instagram-outlined',
    to: 'https://instagram.com',
  },
  {
    icon: 'ri:facebook-fill',
    to: 'https://facebook.com',
  },
  {
    icon: 'ri:twitter-x-fill',
    to: 'https://twitter.com',
  },
  {
    icon: 'mdi:pinterest',
    to: 'https://pinterest.com',
  },
  {
    icon: 'ic:round-tiktok',
    to: 'https://tiktok.com',
  },
];
</script>

<template>
  <footer class="p-6 w-full flex flex-col gap-6 lg:grid lg:grid-cols-3 lg:grid-rows-1 border-t border-[#DDDDDD] box-border">
    <div class="flex flex-col gap-3 justify-center items-center lg:justify-start lg:items-start">
      <NuxtLink to="/" class="text-22px line-height-30px font-700">
        <LogoBig />
      </NuxtLink>

      <span class="hidden lg:block c-text-inactive text-small">
        WeEngrave, 2024. All Rights Reserved
      </span>
    </div>

    <nav>
      <ul class="flex flex-col lg:flex-row justify-center items-center lg:h-full gap-4">
        <li v-for="element in menuElements" :key="element.name">
          <NuxtLink :to="element.to" class="c-text-secondary text-small hover:c-text-regular transition-colors">
            {{ element.name }}
          </NuxtLink>
        </li>
      </ul>
    </nav>

    <div class="flex gap-2 justify-center lg:justify-end items-center h-full">
      <NuxtLink
        v-for="social in socials"
        :key="social.icon"
        :to="social.to"
        class="flex justify-center items-center h-8 w-8 c-icons-primary hover:bg-hover rounded-sm"
      >
        <Icon :name="social.icon" class="w-5 h-5" />
      </NuxtLink>
    </div>

    <span class="flex justify-center lg:hidden c-text-inactive text-small">
      WeEngrave, 2024. All Rights Reserved
    </span>
  </footer>
</template>
