<script setup lang="ts">
import { provide, watch } from 'vue';
import { navigateTo, useCookie } from '#app';
import BaseFooter from '~/shared/components/footer/base-footer.vue';
import LineNotification from '~/shared/components/notifications/line-notification.vue';
import BaseSidebar from '~/shared/components/sidebar/base-sidebar.vue';
import { useNotificationManager } from '~/shared/composables/notification/use-notification-manager';
import { profileProvideKey } from '~/shared/provide/profile-provide-key';
import { sessionTokenKey } from '~/types/session';
import { useProfile } from '~/shared/composables/profile/use-profile';

const sessionToken = useCookie(sessionTokenKey);

watch(sessionToken, () => {
  if (!sessionToken.value) {
    navigateTo('/login');
  }
});

const {
  notificationAnimationDuration,
  isNotificationOpened,
  currentNotification,
} = useNotificationManager();

const { profile, execute } = useProfile();

provide(profileProvideKey, profile);
</script>

<template>
  <LineNotification
    class="z-1000"
    :variant="currentNotification?.type"
    :message="currentNotification?.message || ''"
    :opened="isNotificationOpened"
    :animation-duration="notificationAnimationDuration"
  />
  <div class="flex min-h-dvh w-full">
    <div class="w-0 lg:w-52 xl:w-65 lg:block">
      <BaseSidebar />
    </div>

    <div class="flex flex-col justify-between min-h-dvh lg:w-[calc(100dvw-208px)] xl:w-[calc(100dvw-260px)] mx-auto">
      <slot />

      <BaseFooter />
    </div>
  </div>
</template>
